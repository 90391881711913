// src/components/Footer.js
import React from 'react';

function Contact() {
    return (
        <h1>Test This is the contact page</h1>
    );
}

export default Contact;
